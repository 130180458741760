<template>
	<div v-if="server_load">
		<ServerInfo :server_data="server_data" />
		<div class="container">
			<el-tabs v-model="active_name">
				<el-tab-pane label="일반" name="first">
					<!-- 영상&이미지
					<h2 class="sub-title">영상 & 이미지</h2>
					<section class="video-image">
						<div class="video">
							<iframe
								width="100%"
								height="100%"
								src="https://www.youtube.com/embed/iDjQSdN_ig8"
								frameborder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
						<div class="image">
							<ul>
								<li>
									<img src="@/assets/images/game1.png" />
								</li>
								<li>
									<img src="@/assets/images/game2.png" />
								</li>
								<li>
									<img src="@/assets/images/game3.png" />
								</li>
								<li>
									<img src="@/assets/images/game4.png" />
								</li>
							</ul>
						</div>
						<div class="icon-jumbo">
							<img src="@/assets/images/round-more.svg" alt="더보기 아이콘" />
						</div>
					</section> -->
					<!-- 통계 -->

					<!-- 소개 -->
					<h2 class="sub-title">소개</h2>
					<section class="content">
						<h3>{{ server_data.title }}</h3>
						<div class="fr-view" v-html="server_data.description">
						</div>
						<!-- <div class="hashtag">
							<span>#미니게임</span>
							<span>#팩션</span>
							<span>#야생</span>
							<span>#RPG</span>
							<span>#전쟁</span>
						</div> -->
					</section>

					<!-- 통계 -->
					<h2 class="sub-title">통계</h2>
					<section>
						<iframe :src="'https://admin.mine.page/getstats?idx='+server_data.idx+'&type=iframe'" class="full stats"></iframe>
					</section>

				
				</el-tab-pane>
				<!-- <el-tab-pane label="배너&보트 버튼" name="second">
				
					<h2 class="sub-title">배너 이미지</h2>
					<section class="forest-banner">
						<div class="forest-banner-top">
							<h2>나노이 서버</h2>
							<a href="#" class="color-main">play.hyperpixelmon.net</a>
						</div>
						<div class="forest-banner-bottom">
							<ul>
								<li>
									<strong>서버 연결 상태</strong>
									<p>온라인</p>
								</li>
								<li>
									<strong>서버 버전</strong>
									<p>1.12</p>
								</li>
								<li>
									<strong>서버 접속인원</strong>
									<p>
										<strong>143</strong>
										/ 500
									</p>
								</li>
							</ul>
						</div>
					</section>

					<h2 class="sub-title">배너 배경</h2>
					<input type="text" readonly />

					<h2 class="sub-title">글자색</h2>
					<input type="text" readonly />

					<h2 class="sub-title">Html Code</h2>
					<input type="text" readonly />

					<h2 class="sub-title">BB Code</h2>
					<input type="text" readonly />

					<h2 class="sub-title">보트 버튼</h2>
					<button class="btn-main">리뷰등록</button>

					<h2 class="sub-title">Html Code</h2>
					<input type="text" readonly />

					<h2 class="sub-title">BB Code</h2>
					<input type="text" readonly />

					<h2 class="sub-title">Vote URL</h2>
					<input type="text" readonly />
				</el-tab-pane> -->
				<el-tab-pane label="추천 내역" name="third">
					<div class="recom-layout">
						<div class="recom-left">
							<h2 class="sub-title">추천 목록</h2>
							<section>
								<div>
									<h3>
										전체
										<span class="color-main">{{votes_all.total}}</span>
									</h3>
									<input
										type="text"
										class="search"
										@change="valoadTable"
										v-model="votes_all.search"
										placeholder="마인크래프트 닉네임"
									/>
								</div>
								<table>
									<thead>
										<tr>
											<td>고유번호</td>
											<td>마인크래프트 닉네임</td>
											<td>추천 시각</td>
											<td>IP</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, idx) in votes_all.votes" :key="idx">
											<td>{{ item.idx }}</td>
											<td>
												<div class="recommender">
													<div class="icon-medium round">
														<img style="border-radius: 50%;" :src="'https://minotar.net/helm/'+item.mc_nick+'/16'" />
													</div>
													<span>{{ item.mc_nick }}</span>
												</div>
											</td>
											<td>{{ item.time }}</td>
											<td>{{ item.ip }}</td>
										</tr>
									</tbody>
								</table>
								<el-pagination v-if="votes_all.load" layout="prev, pager, next" :total="votes_all.total" :page-size="10" :current-page="votes_all.page" @current-change="vachangePage"> </el-pagination>
							</section>
						</div>
						<!-- <div class="recom-right">
							<h2 class="sub-title">이번달 추천 TOP10</h2>
							<section>
								<table>
									<thead>
										<tr>
											<td>순위</td>
											<td>마인크래프트 닉네임</td>
											<td>추천</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, idx) in votes_most.votes" :key="idx">
											<td>{{ idx + 1 }}</td>
											<td>
												<div class="recommender">
													<span class="icon-medium round">
														<img style="border-radius: 50%;" :src="'https://minotar.net/helm/'+item.mc_nick+'/16'" />
													</span>
													<span>{{ item.mc_nick }}</span>
												</div>
											</td>
											<td>{{ item.count }}</td>
										</tr>
									</tbody>
								</table>
							</section>
						</div> -->
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="리뷰" name="fourth">

					<h2 class="sub-title">댓글</h2>
					<section class="review">
						<div class="select-star">
							<h3>별점을 선택해주세요.</h3>
							<star-rating
								:increment="0.5"
								inactive-color="#C1CBD9"
								active-color="#63CC80"
								rounded-corners="true"
								glow="1"
								padding="1"
								star-size="42"
								:show-rating="false"
								@rating-selected="setRating"
								:rating="rating"
							></star-rating>
						</div>
						<div class="review-input">
							<textarea
								name=""
								id=""
								cols="30"
								rows="10"
								placeholder="댓글을 입력해주세요."
							></textarea>
							<button class="btn-main">댓글 등록</button>
						</div>
						<StarStatistics />
						<h3>
							댓글
							<span class="color-main">3</span>
						</h3>
						<ReviewCard />
					</section>

				</el-tab-pane> -->
			</el-tabs>

			<!-- <BottomButton /> -->
		</div>
	</div>
</template>

<script>
import { apiUrl } from '../constants/config';
import axios from 'axios';
// import StarRating from 'vue-star-rating'
// import ReviewCard from '@/components/review-card'
// import BottomButton from '@/components/bottom-button'
import ServerInfo from '@/components/server-info'
// import StarStatistics from '@/components/star-statistics'
import { Loading } from 'element-ui';
var loadingInstance;

export default {
	components: {
		// StarRating,
		// ReviewCard,
		// BottomButton,
		ServerInfo,
		// StarStatistics,
	},
	data() {
		return {
			server_load: false,
			server_data: {},
			rating: 0,
			active_name: 'first',
			votes_all: {
				page: 1,
				total: 0,
				search: "",
				votes: [

				],
				load: false
			},
			votes_most: {
				load: false,
				votes: [

				]
			}
		}
	},

	methods: {
		setRating(rating) {
			this.rating = rating
		},
		valoadTable(){
			var vm = this;
			axios.post(apiUrl+'/server/'+this.$route.params.host+'/votes', {
				page: vm.votes_all.page,
				search: vm.votes_all.search
			})
			.then(function(response) {
				if(!response.data.success)
					vm.$router.push("/");
				else{
					vm.votes_all.total = response.data.total;
					vm.votes_all.votes = response.data.votes;
					vm.votes_all.load = true;
				}
			})
			.catch(function(error) {
				console.log(error);
			});
		},
		vachangePage(page){
			this.votes_all.page = page;
			this.valoadTable();
		},
		// maloadTable(){
		// 	var vm = this;
		// 	axios.get(apiUrl+'/server/'+this.$route.params.host+'/mostVotes')
		// 	.then(function(response) {
		// 		if(!response.data.success)
		// 			vm.$router.push("/");
		// 		else{
		// 			vm.votes_most.votes = response.data.votes;
		// 			vm.votes_most.load = true;
		// 		}
		// 	})
		// 	.catch(function(error) {
		// 		console.log(error);
		// 	});
		// },
	},

	beforeCreate(){
		loadingInstance = Loading.service({ fullscreen: true });

		var vm = this;
		axios.get(apiUrl+'/server/'+this.$route.params.host)
		.then(function(response) {
			if(!response.data.success)
				vm.$router.push("/");
			else{
				vm.server_data = response.data.data;
				vm.server_load = true;
			}
		})
		.catch(function(error) {
			console.log(error);
		});
	},

	mounted(){
		this.valoadTable();
		//this.maloadTable();
		loadingInstance.close();
	}

	
}
</script>

<style scoped>

.stats {
	height: 500px;
	border: 0;
	overflow-y: hidden;
}
.forest-banner {
	width: 100%;
	height: 205px;
	padding: 0;
	border: 1px solid #e5e5e5;
	text-align: center;
	margin-bottom: 50px;
	box-sizing: border-box;
	background-image: url('../assets/images/forest.png');
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 5px;
	color: #fff;
	font-size: 15px;
}

.forest-banner .forest-banner-top {
	text-align: center;
	border-bottom: 1px solid #2c575a;
	padding: 30px;
}

.forest-banner .forest-banner-bottom ul li {
	float: left;
	width: 33%;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
}

button,
input {
	margin-bottom: 50px;
}

.recom-layout {
	overflow: hidden;
}

.recom-layout h3 {
	float: left;
	padding: 15px 0;
}

.recom-layout .search {
	float: right;
	width: 250px;
}

.recom-left {
	width: 100%;
	float: left;
	box-sizing: border-box;
}
.recom-right {
	width: 38%;
	float: right;
	box-sizing: border-box;
}

.recommender {
	margin: 0 auto;
	display: inline-block;
	overflow: hidden;
}

.recommender .icon-medium {
	float: left;
	margin-right: 5px;
}

.recommender span {
	line-height: 1;
	float: left;
}

/* 영상 & 이미지 */
.video-image {
	position: relative;
	overflow: hidden;
	border-radius: 5px;
	padding: 0;
	border: none;
}

.video-image .icon-jumbo {
	position: absolute;
	right: 20px;
	bottom: 20px;
}

.video-image .video {
	float: left;
	width: 50%;
	height: 300px;
}

.video-image .image {
	float: right;
	width: 50%;
}

.video-image .image ul li {
	width: 50%;
	float: left;
	height: 150px;
}

/* 댓글 */
.review .select-star {
	text-align: center;
	margin-bottom: 30px;
}

.review .select-star .vue-star-rating {
	margin: 0 auto;
	display: inline-block;
}

.review .review-input {
	overflow: hidden;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 30px;
}

.review .review-input textarea {
	resize: none;
	width: 100%;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	padding: 20px;
	height: 100px;
	border-radius: 5px;
}

.review .review-input button {
	float: right;
	position: absolute;
	right: 20px;
	top: 20px;
}

section {
	padding: 20px;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	margin-bottom: 50px;
}

.hashtag {
	padding-top: 20px;
}

.hashtag span {
	padding-right: 10px;
	color: #63cc80;
}

@media (max-width: 1080px) {
	.recom-left,
	.recom-right {
		width: 100%;
	}
}

@media (max-width: 500px) {
	.video-image .video,
	.video-image .image {
		width: 100%;
	}
}
</style>
