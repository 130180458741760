<template>
	<div class="server-info-wrap">
		<div class="server-info">
			<section class="server">
				<div class="server-image-wrap">
					<div class="server-image">
						<img class="full" :src="server_data.icon == null ? require(`@/assets/images/server.webp`) : server_data.icon" />
					</div>
					<div class="server-name">
						<h4>{{ server_data.name }}</h4>
						<p class="color-point" style="color: #44d144;" v-if="server_data.status">온라인</p>
						<p class="color-point" style="color: red;" v-else>오프라인</p>
						<p class="color-grey">({{ server_data.lastcheck }} 확인)</p>
						<button
							class="btn-point-gra mt-10 btn-medium"
							@click="modalOpenRecom"
						>
							추천하기
						</button>
					</div>
				</div>
				<div class="server-info-right">
					<!-- <div class="server-info-top">
						<div class="address">
							<p @click="doCopy" class="cursor-pointer">
								<strong class="pr-5">{{ server_data.host + (server_data.port == 25565 ? "" : ":"+server_data.port.toString()) }}</strong>
								<span class="icon-medium">
									<img src="@/assets/images/icon-copy.svg" />
								</span>
							</p>
							<span>{{ server_data.version }}</span>
						</div>
						<div class="server-tag">
							<span>#미니게임</span>
							<span>#팩션</span>
							<span>#야생</span>
							<span>#RPG</span>
							<span>#모드</span>
							<span>#전쟁</span>
							<span>#모험</span>
						</div>
					</div> -->
					<div class="server-info-center">
						<ul>
							<li style="width: 30%;">
								<p @click="doCopy" class="cursor-pointer">
								<strong class="pr-5">{{ server_data.host + (server_data.port == 25565 ? "" : ":"+server_data.port.toString()) }}</strong>
								<span class="icon-medium">
									<img src="@/assets/images/icon-copy.svg" />
								</span>
								</p>
							</li>
			
							<li style="float: right;" class="cursor-pointer" v-for="link in server_data.links" :key="link.id" @click="openLink(link.url)">
								<div class="icon-large">
									<img src="@/assets/images/icon-globe.svg" class="full" />
								</div>
								<span>{{ link.name }}</span>
							</li>
						
						</ul>
					</div>
					<div class="badge-wrap">
											<span
												class="badge badge-grey"
												v-for="type in server_data.types"
												:key="type"
												>{{ type }}</span
											>
					</div><br/>
					<span>{{server_data.short_description}}</span><br/><br/>
					<div class="server-info-bottom bg-lightgrey">
						<ul>
							<li>
								<strong>버전</strong>
								<p>{{ server_data.version }}</p>
							</li>
							<li>
								<strong>플레이어</strong>
								<p>{{ server_data.players_online }} / {{ server_data.players_total }}</p>
							</li>
							<li>
								<strong>업타임</strong>
								<p>{{ server_data.uptime }}%</p>
							</li>
							<li>
								<strong>추천</strong>
								<p>{{ server_data.votes }}</p>
							</li>
							<li>
								<strong>평점</strong>
								<p>
							{{ server_data.score }}</p>
							</li>
						</ul>
					</div>
				</div>
			</section>

		</div>

		<el-dialog title="추천하기" :visible.sync="modal_recom" custom-class="vote_modal">
			<h3>마인페이지 추천 안내</h3>
			
<b>아래와 같은 부당한 방법으로 서버를 추천하실 경우 불이익이 발생할 수 있습니다.</b><br/>
<br/>
<p style='color: red;'>ㄱ. 부계정을 이용한 추천<br/>
ㄴ. 매크로 프로그램을 이용한 추천<br/>
ㄷ. 3G, LTE, 5G 와 같은 이동통신망, PC방, 공공장소, 공용 WIFI, VPN 등 을 이용한 추천<br/>
ㄹ. 기타 부정한 방법을 통한 추천<br/></p>
<br/>
마인페이지는 1일 1인 1회 추천으로 추천 가능 횟수를 제한 하고 있습니다.<br/>
부정 추천으로 의심될 경우 무통보 추천 취소 조치합니다.<br/>
공정한 서버 순위를 위해 추천을 조작하는 행위는 강력하게 제재 합니다.<br/>
<br/>
추천 조작 정황이 발견될 경우 순위권 제외, 사이트 접속차단, 추천 수 초기화, 이용정지 등을 진행합니다.<br/><br/>
			<form @submit.prevent="vote">
			<input type="text" placeholder="마인크래프트 닉네임" v-model="mc_nick" :disabled="!voteload" />
			<button class="btn-point-gra btn-full mt-10" :disabled="!voteload">
				{{ votebutton }}
			</button>
			<br/><br/>
			<p class="error">{{ voteerror }}</p>
			</form>
		</el-dialog>
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
export default {
	props: ['server_data'],
	data() {
		return {
			mc_nick: "",
			voteerror: "",
			votebutton: "추천하기",
			voteload: true,
			host: this.server_data.host + (this.server_data.port == 25565 ? "" : ":"+this.server_data.port.toString()),
			modal_recom: false,
		}
	},
	methods: {
		openLink(url){
			window.open(url);
		},
		modalOpenRecom() {
			var vm = this;
			if(this.server_data.status == 0){
				vm.$swal.fire({
					title: '서버가 현재 오프라인 상태입니다.',
					html: "추천 보상이 지급되지 않을 수 있습니다.<br/>그래도 추천하시겠습니까?",
					icon: 'warning',
					showDenyButton: true,
					showCancelButton: false,
					confirmButtonText: '추천하기',
					denyButtonText: `닫기`,
					}).then((result) => {
					if (result.isConfirmed) {
						vm.modal_recom = true
					} 
				})
			}
			else{
				vm.modal_recom = true;
			}
		},
		doCopy() {
			this.$copyText(this.host).then(
				() => {
					alert(`${this.host}가 복사되었습니다.`)
				},
				() => {
					alert('복사되지 않았습니다.')
				}
			)
		},
		async vote(){
			if(!this.voteload)
				return;
			
			this.voteerror = "";
			this.voteload = false;
			this.votebutton = "추천 중";

		    await this.$recaptchaLoaded()
      		const token = await this.$recaptcha('vote')

			this.mc_nick = this.mc_nick.trim();
			if(this.mc_nick == "")
			{
				this.voteerror = "마인크래프트 닉네임을 입력하세요.";
				this.voteload = true;
				this.votebutton = "추천하기";
				return;
			}

			var specialRule = /[`~!@#$%^ &*|\\'";:/?]/gi;
			if(specialRule.test(this.mc_nick)){
				this.voteerror = "마인크래프트 닉네임에는 특수문자가 포함될 수 없습니다.";
				this.voteload = true;
				this.votebutton = "추천하기";
				return;
			}

			var koreanRule = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
			if(koreanRule.test(this.mc_nick)){
				this.voteerror = "마인크래프트 닉네임에는 한글이 포함될 수 없습니다.";
				this.voteload = true;
				this.votebutton = "추천하기";
				return;
			}


			var vm = this;

			axios.post(apiUrl+'/server/'+this.server_data.host+'/vote',{
				mc_nick: this.mc_nick,
				token: token
			}, { withCredentials: true })
			.then(function(response) {
				if(response.data.success)
				{
					vm.modal_recom = false;

					vm.$swal.fire(
						'추천이 요청이 완료되었습니다.',
						"<b>추천이 요청이 처리되기까지 최대 15분이 소요될 수 있습니다.</b><br/>요청 ID: <a href='/votestatus?messageId="+ response.data.messageId +"'>" + response.data.messageId + "</a><br/>(처리 상태를 확인하시려면 요청 ID를 클릭하세요)",
						'success'
					).then(() => {
						vm.voteload = true;
						vm.votebutton = "추천하기";
						vm.mc_nick = "";
					} 
					)


					// var votifier_success = response.data.votifier_success;
					// if(votifier_success == "none")
					// 	votifier_success = "성공";
					// if(votifier_success == "true")
					// 	votifier_success = "Votifier 성공";
					// if(votifier_success == "false")
					// 	votifier_success = "Votifier 실패";


					// vm.$swal.fire(
					// 	'추천이 완료되었습니다.',
					// 	"마인크래프트 닉네임: "+vm.mc_nick+"<br/>추천 일시: "+response.data.time+"<br/>고유번호: "+response.data.idx+"<br/>상태: "+votifier_success,
					// 	'success'
					// ).then(() => {
					// 	vm.voteload = true;
					// 	vm.votebutton = "추천하기";
					// 	vm.mc_nick = "";
					// } 
					// )
					
					
				}
				else{
					vm.voteload = true;
					vm.votebutton = "추천하기";
					vm.voteerror = response.data.message;
				}
			})
			.catch(function(error) {
				console.log(error);
			});

			
		}
	},
}
</script>

<style scoped>
.server-info-wrap {
	width: 1500px;
	margin: 0 auto;
	position: relative;
}

.vote_modal {
	width: 30%;
}

.help {
	position: fixed;
	border: 1px solid #e5e5e5;
	background: white;
	width: 150px;
	display: inline-block;
	border-radius: 5px;
}

.help strong {
	border-bottom: 1px solid #e5e5e5;
	padding: 15px;
	display: block;
}

.help ul li {
	padding: 10px 15px;
}

.help ul li:first-child {
	background-color: #f0fef0;
}

.help ul li:first-child a {
	color: #63cc80;
}

.server-info {
	overflow: hidden;
	margin-bottom: 30px;
	width: 1140px;
	margin: 0 auto;
	padding-bottom: 30px;
}

.error {
	color: red;
	font-size: 14px;
}
.server {
	overflow: hidden;
	width: 1140px;
	float: left;
	background-color: #fff;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-right: 20px;
}

.server-name {
	text-align: center;
}

.server-name p {
	font-size: 13px;
}

.recom-form {
	float: right;
	width: 180px;
	background-color: #fff;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 5px;
	height: 280px;
}

.recom-form .recom-image {
	padding-bottom: 30px;
	overflow: hidden;
}

.recom-form .recom-image .icon-recom {
	float: left;
	width: 100%;
}

.recom-form .recom-image .recom-count {
	float: right;
	padding: 15px 0px;
}

.server-info-right {
	width: 80%;
	float: right;
	box-sizing: border-box;
}

.server-image-wrap {
	float: left;
	margin-right: 5%;
	box-sizing: border-box;
	width: 15%;
}

.server-image-wrap .server-image {
	padding-bottom: 10px;
	width: 110px;
	height: 110px;
	margin: 0 auto;
}

.server-info-top {
	overflow: hidden;
	padding-bottom: 20px;
	border-bottom: 1px solid #e5e5e5;
}

.server-info-top div {
	float: left;
	width: 33.3%;
	padding: 0 20px 20px 20px;
	box-sizing: border-box;
	height: 54px;
	border-right: 1px solid #e5e5e5;
}

.server-info-top div:last-child {
	border: none;
}

.server-info-top .server-tag span {
	padding-right: 10px;
}

.server-info-center {
	width: 100%;
	padding: 15px;
}

.server-info-center ul li {
	float: left;
	width: 15%;
}

.server-info-center ul li .icon-large {
	float: left;
}

.server-info-center ul li span {
	padding-left: 5px;
}

.server-info-bottom {
	padding: 20px;
}
.server-info-bottom ul li {
	float: left;
	width: 20%;
	box-sizing: border-box;
	text-align: center;
}


@media (max-width: 1500px) {
	.help {
		display: none;
	}
	.server-info-wrap {
		width: 1140px;
	}
}

@media (max-width: 1200px) {
	.server {
		width: 100%;
	}

	.server-info-wrap {
		width: 100%;
		padding: 0 16px;
		box-sizing: border-box;
	}

	.server-info {
		width: 100%;
	}

	.recom-form {
		display: none;
	}

	.server-name button {
		display: inline-block;
	}
}
@media (max-width: 1024px) {
	.recom-image {
		width: 150px;
		margin: 0 auto;
	}

	.recom-count {
		text-align: center;
		color: #222;
	}
}

@media (max-width: 940px) {
	.server {
		width: 100%;
		box-sizing: border-box;
	}

	.server-image-wrap {
		width: 100%;
		margin: 0 0 30px 0;
	}

	.server-image-wrap .server-image {
		margin: 0 auto;
	}

	.server-info-right {
		width: 100%;
		margin-bottom: 10px;
	}

	.server-info .server-info-top {
		padding-bottom: 10px;
	}

	.server-info .server-info-top div {
		height: auto;
		padding: 0 0 10px 0;
		width: 100%;
		border-right: none;
	}

	.server-info-center {
		display: inline-block;
		padding-top: 10px;
	}

	.server-info-center ul li {
		padding: 5px;
		width: 50%;
		box-sizing: border-box;
	}
}

@media (max-width: 420px) {
	.server-info-center ul li {
		padding: 5px;
		width: 100%;
		box-sizing: border-box;
	}

	.server-info-bottom ul li {
		float: left;
		width: 33%;
		box-sizing: border-box;
		text-align: center;
		padding: 10px;
	}
}
</style>
